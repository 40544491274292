$black: #1a1621;
$primary: #8F4066;
$dark: #25222e;

$font-family-base: 'Poppins', sans-serif;
$font-weight-base: 200;

$border-radius: .5rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-pill: 50rem;

@import "~bootstrap/scss/bootstrap";

%heading {
  font-weight: $font-weight-base;
}

.btn {
  font-weight: $font-weight-base;
  padding: ($spacer * .65) ($spacer * 1);
}

.form-control {
  border-width: 0;
}

hr {
  background-color: #302e35;
}
