body {
  background-color: $black;
}

.rdtPicker {
  color: $black;
  .rdtCount {
    height: auto;
  }
  .rdtSwitch:hover {
    background: #eee;
    cursor: pointer;
  }
}

.flip-countdown {
  .flip-countdown-card-sec {
    span {
      width: 100%;
    }
    span, span::after, span::before {
      text-shadow: 0px 1px 3px #fff;
    }
  }
  &.theme-dark {
    .card__top, .card__back::before {
      background-color: #000 !important;
    }
  }
  &.size-small {
    .flip-countdown-card-sec {
      width: 30px;
    }
    .flip-countdown-piece {
      margin: 0 0.5em;
    }
  }
  &.size-medium {
    .flip-countdown-card-sec {
      width: 60px;
    }
  }
}

.Image-Ratio-600-400 {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 66.6%; // ratio 640-480
  }

  img {
    display: block;
    max-height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.Image-Square {
  position: relative;
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.Art-Background {
  background: url('../img/artbg.svg');
  background-position: center;
  background-size: cover;
}

.Newsletter-Background {
  background: url('../img/newsletter.png');
  background-position: center;
  background-size: cover;
}

.Home-Background-1 {
  background: url('../img/home1.jpg');
  background-position: center;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 100px;
  h1 {
    font-size: ($font-size-base * 4);
  }
  p {
    padding-bottom: 400px;
  }
}

.SocialLink {
  font-size: ($font-size-base * 3);
}
